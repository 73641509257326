function notify(message, type)
{
     noty({
            layout: 'center',
            type: type,
            theme: 'relax',
            text: message,
            timeout  : 5000,
            animation: {
            open: 'animated flipInX',
            close: 'animated flipOutX',
        }
    });
}